/** @jsx jsx */
import { graphql } from "gatsby";
import { AllEvents, FeaturedEvents } from "gatsby-theme-event-pages";
import React, { memo } from "react";
import Bleed from "shared/components/Bleed";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetEvents" }>;
}

const EventsWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  shouldBleed,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const { theaterId } = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();
  const { theme } = useThemeUI();

  switch (widget.eventsShape?.display) {
    case "FEATURED":
      return (
        <WidgetWrapper
          isFirst={isFirst}
          isLast={isLast}
          isNested={isNested}
          containerSize={containerSize || "FULL"}
          widget={widget}
          prevWidget={prevWidget}
          nextWidget={nextWidget}
          contained={false}
        >
          <Bleed
            horizontal={
              shouldBleed
                ? // FIXME: type this
                  // @ts-expect-error no type for containerGutter
                  theme.sizes?.containerGutter
                : undefined
            }
          >
            <FeaturedEvents
              theaterId={theaterId || selectedTheaterId || undefined}
              isNested={isNested}
              containerSize={containerSize || "FULL"}
            />
          </Bleed>
        </WidgetWrapper>
      );
    case "ALL_EVENTS":
      return (
        <WidgetWrapper
          isFirst={isFirst}
          isLast={isLast}
          isNested={isNested}
          containerSize={containerSize}
          widget={widget}
          prevWidget={prevWidget}
          nextWidget={nextWidget}
        >
          <AllEvents />
        </WidgetWrapper>
      );
    default:
      return null;
  }
};

export const query = graphql`
  fragment WidgetEvents on WidgetEvents {
    id
    __typename
    eventsShape {
      display
    }
  }
`;

export default memo(EventsWidget);
